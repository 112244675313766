/***********
 import packages
***********/
import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { createStore } from 'vuex'

import App from './App.vue'

/***********
 import components
***********/
import StartHere from './components/StartHere.vue';
import DiagnosisQuestions from './components/DiagnosisQuestions.vue';
import DiagnosisResults from './components/DiagnosisResults.vue';

/***********
 route settings
***********/
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: [
        {path: '/', component: StartHere},
        {path: '/questions', component: DiagnosisQuestions},
        {path: '/results', component: DiagnosisResults},
    ]
});

/***********
 modules
***********/
// question number
const questionNumber = {
    state() {
        return {
            // Question Number (up to 12)
            currentQuestionNo: 0,
        }
    },
    mutations: {
        // Question Number
        increaseQuestionNo(state) {
            state.currentQuestionNo++;
        },
        decreaseQuestionNo(state) {
            state.currentQuestionNo--;
        },
        resetQuestionNo(state) {
            state.currentQuestionNo = 0;
        }
    },
    getters: {
        currentQuestionNo(state) {
            return state.currentQuestionNo;
        }
    },
    actions: {
        currentQuestionNo(state) {
            return state.currentQuestionNo;
        }
    }
};

// gravity questions
const q1 = {
    state() {
        return {
            q1: "downLeft"
        }
    },
    mutations: {
        changeQ1(state, payload) {
            state.q1 = payload
        }
    },
    getters: {
        q1(state) {
            return state.q1
        }
    }
};
const q2 = {
    state() {
        return {
            q2: "downLeft"
        }
    },
    mutations: {
        changeQ2(state, payload) {
            state.q2 = payload
        }
    },
    getters: {
        q2(state) {
            return state.q2
        }
    }
};
const q3 = {
    state() {
        return {
            q3: "downLeft"
        }
    },
    mutations: {
        changeQ3(state, payload) {
            state.q3 = payload
        }
    },
    getters: {
        q3(state) {
            return state.q3
        }
    }
};
const q4 = {
    state() {
        return {
            q4: "upLeft"
        }
    },
    mutations: {
        changeQ4(state, payload) {
            state.q4 = payload
        }
    },
    getters: {
        q4(state) {
            return state.q4
        }
    }
};
const q5 = {
    state() {
        return {
            q5: "upLeft"
        }
    },
    mutations: {
        changeQ5(state, payload) {
            state.q5 = payload
        }
    },
    getters: {
        q5(state) {
            return state.q5
        }
    }
};
const q6 = {
    state() {
        return {
            q6: "upLeft"
        }
    },
    mutations: {
        changeQ6(state, payload) {
            state.q6 = payload
        }
    },
    getters: {
        q6(state) {
            return state.q6
        }
    }
};

// five gyo (up to 9)
const tue = {
    state() {
        return {
            tue:0
        }
    },
    mutations: {
        increaseTUE(state) {
            state.tue++;
        },
        decreaseTUE(state) {
            state.tue--;
        },
        resetTUE(state) {
            state.tue = 0;
        }
    },
    getters: {
        tue(state) {
            return state.tue;
        }
    }
};
const wed = {
    state() {
        return {
            wed:0
        }
    },
    mutations: {
        increaseWED(state) {
            state.wed++;
        },
        decreaseWED(state) {
            state.wed--;
        },
        resetWED(state) {
            state.wed = 0;
        }
    },
    getters: {
        wed(state) {
            return state.wed;
        }
    }
};
const thu = {
    state() {
        return {
            thu:0
        }
    },
    mutations: {
        increaseTHU(state) {
            state.thu++;
        },
        decreaseTHU(state) {
            state.thu--;
        },
        resetTHU(state) {
            state.thu = 0;
        }
    },
    getters: {
        thu(state) {
            return state.thu;
        }
    }
};
const fri = {
    state() {
        return {
            fri:0
        }
    },
    mutations: {
        increaseFRI(state) {
            state.fri++;
        },
        decreaseFRI(state) {
            state.fri--;
        },
        resetFRI(state) {
            state.fri = 0;
        }
    },
    getters: {
        fri(state) {
            return state.fri;
        }
    }
};
const sat = {
    state() {
        return {
            sat:0
        }
    },
    mutations: {
        increaseSAT(state) {
            state.sat++;
        },
        decreaseSAT(state) {
            state.sat--;
        },
        resetSAT(state) {
            state.sat = 0;
        }
    },
    getters: {
        sat(state) {
            return state.sat;
        }
    }
};


/***********
 store settings
***********/
const store = createStore({
    modules: {
        currentQuestion: questionNumber,
        q1: q1,
        q2: q2,
        q3: q3,
        q4: q4,
        q5: q5,
        q6: q6,
        tue: tue,
        wed: wed,
        thu: thu,
        fri: fri,
        sat: sat
    }
});

/***********
 initialize
***********/
const app = createApp(App);

/***********
 mounting settings
***********/
app.use(store);
app.use(router);
app.mount('#app');