<template>
    <div class="q-cont">
        <p class="q-cont__txt">{{ currentQuestion }}</p>
        <p v-if="currentQuestionNo <= 6" class="q-cont__txt--hsk">どちらかといえば当てはまる方を選択してください</p>
        <p v-if="currentQuestionNo > 6" class="q-cont__txt--hsk">少しでも当てはまるものがあればチェックしてください</p>
    </div>
</template>
<style lang="scss">
.q-cont {
    margin-bottom: 40px;
    &__txt {
        font-size: 18px;
        margin-bottom: 15px;
        white-space: pre-line;
        line-height: 1.5;
        &--hsk {
            font-size: 12px;
        }
    }
}
</style>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            questions: [
                {
                    text:"足を組む時どちらが上になりやすいですか？",
                    id:1
                },
                {
                    text:"立っている時\nどちらの足が後ろにいきやすいですか？",
                    id:2
                },
                {
                    text:"下痢と便秘、なりやすいのはどちら？",
                    id:3
                },
                {
                    text:"普段、どちらの脇腹が痛みやすいですか？",
                    id:4
                },
                {
                    text:"普段、どちらの肩が痛みやすいですか？",
                    id:5
                },
                {
                    text:"普段、どちらの頭が痛みやすいですか？",
                    id:6
                },
                {
                    text:"手足について\nあてはまる項目をすべてお選びください。",
                    id:7
                },
                {
                    text:"腹部について\nあてはまる項目をすべてお選びください。",
                    id:8
                },
                {
                    text:"胸について\nあてはまる項目をすべてお選びください。",
                    id:9
                },
                {
                    text:"顔や見た目について\nあてはまる項目をすべてお選びください。",
                    id:10
                },
                {
                    text:"疲れについて\nあてはまる項目をすべてお選びください。",
                    id:11
                },
                {
                    text:"気持ちについて\nあてはまる項目をすべてお選びください。",
                    id:12
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['currentQuestionNo']),
        currentQuestion() {
            return this.questions[this.currentQuestionNo-1].text
        }
    }
}
</script>