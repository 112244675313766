<template>
    <div class="container">
        <img src="@/assets/abc_logo_yoko.png" alt="asian beauty clinic" class="start__logo">
        <transition-group name="top-img">
            <img v-if="show" @load="mainVisual = true" src="@/assets/face_sat.png" alt="" class="start__face">
            <div class="start__main" v-if="show && mainVisual">
                <p class="start__main--header">\ 12の質問で自分の体質がわかる! /</p>
                <h1 class="start__main--title">五行・重心側診断</h1>
                <img src="@/assets/border_01.png" alt="section" class="start__main--border">
                <p class="start__main--copy">
                    いわゆる未病や不調と呼ばれる症状は<br>
                    人の生まれもった体質や<br>
                    身体のバランスによって異なります。<br>
                    これらを知っておくことで、<br>
                    自分に合った対処法を選びやすくなるのです。
                </p>
                <img src="@/assets/border_02.png" alt="section" class="start__main--border">
            </div>
            <div class="start__cont" v-if="show">
                <router-link to="/questions" @click="multipleHandler" class="start__cont--btn">
                    <span>〜 所要時間は3~5分 〜</span>
                    診断をはじめる
                </router-link>
                <div class="start__cont--alert">
                    <p class="start__cont--alert--txt"><em>注意：</em>ブラウザの「更新」「戻る」ボタンを押すと途中経過がリセットされてしまいます。診断中は、ブラウザの「更新」「戻る」ボタンを押さないようにご注意ください。</p>
                </div>
            </div>
            <div class="ao" v-if="show">
                <p class="ao__txt">Powered by</p>
                <a href="https://ale-one.com/">
                    <img src="@/assets/ALEONE_logo_long.png" alt="ale one" class="ao__logo">
                </a>
            </div>
        </transition-group>
    </div>
</template>
<style lang="scss">
.container {
    max-width: 720px;
    margin: 15px auto;
    text-align: center;
}
.start {
    &__logo {
        display: block;
        width: 200px;
        margin: 0 auto;
    }
    &__face {
        display: block;
        width: 200px;
        margin: 0 auto;
    }
    &__main {
        margin: 30px 0;
        text-align: center;
        &--header {
            font-size: 14px;
            line-height: 1.5;
        }
        &--title {
            font-size: 36px;
            font-family: 'yasashisa';
        }
        &--border {
            height: 20px;
            margin: 30px 0;
        }
        &--copy {
            font-size: 16px;
            line-height: 1.5;
        }
    }
    &__cont {
        text-align: center;
        margin-bottom: 90px;
        &--btn {
            display: block;
            width: 90%;
            padding: 25px 0;
            margin: 0 auto;
            background-color: #679653;
            border-radius: 10px;
            font-family: 'yasashisa';
            font-size: 24px;
            color: #fff;
            text-decoration: none;
            border: none;
            cursor: pointer;
            appearance: none;
            outline: none;
            span {
                display: block;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
        &--alert {
            display: block;
            width: 90%;
            margin: 15px auto;
            background-color: #FCD7D7;
            color: red;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 10px;
            text-align: left;
            &--txt {
                font-family: Arial, Helvetica, sans-serif;
            }
        }
    }
}
.ao {
    padding: 120px 10px 60px 10px;
    &--q {
        padding: 90px 10px 100px 10px;
    }
    &__txt {
        font-size: 0.8rem;
        margin-bottom: 10px;
    }
    &__logo {
        width: 50%;
    }
}

// transition css
.top-img-enter-from {
    opacity: 0;
    transform: translateY(60px);
};
.top-img-enter-active {
    transition: all 0.7s ease;
};
.top-img-enter-leave {
    opacity: 1;
};
</style>
<script>
export default {
    data() {
        return {
            show: false,
            mainVisual: false
        }
    },
    methods: {
        multipleHandler() {
            this.increaseQuestionNo();
            this.scrollTop();
        },
        increaseQuestionNo() {
            this.$store.commit('increaseQuestionNo');
        },
        scrollTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
        }
    },
    mounted() {
        this.show =true
    }
}
</script>
