<template>
    <div class="container" v-if="currentQuestionNo != 0">
        <img src="@/assets/abc_logo_yoko.png" alt="asian beauty clinic" class="q-logo">
        <transition name="q-no">
            <img :src="require(`@/assets/question_${currentQuestionNo}.png`)" v-if="qIsVisible" @load="qNoLoaded = true" alt="asian beauty clinic" class="q-no">
        </transition>
        <transition name="q-cont">
            <question-content v-if="contIsVisible && qNoLoaded"></question-content>
        </transition>
        <transition name="q-choice">
            <question-choices v-if="qNoLoaded"></question-choices>
        </transition>
        <div class="next-btn">
            <div class="next-btn__wrap">
                <button @click="goNext" class="next-btn__next">{{ btnMessage }}</button>
                <button @click="backQuestion" class="next-btn__back">戻る</button>
            </div>
            <!-- <button @click="multipleHandler" class="back-to-top">最初からやり直す</button> -->
        </div>
        <div class="ao ao--q">
            <p class="ao__txt">Powered by</p>
            <a href="https://ale-one.com/">
                <img src="@/assets/ALEONE_logo_long.png" alt="ale one" class="ao__logo">
            </a>
        </div>
    </div>
    <div class="container" v-if="currentQuestionNo === 0">
        <img src="@/assets/abc_logo_yoko.png" alt="asian beauty clinic" class="q-logo">
        <div class="error-handling">
            <h1 class="error-handling__ttl">エラーが発生しました</h1>
            <p class="error-handling__txt">お手数ですが、<br>診断トップからやり直してください。</p>
            <div class="start__cont--alert">
                <p class="start__cont--alert--txt"><em>注意：</em>ブラウザの「更新」「戻る」ボタンを押すと途中経過がリセットされてしまいます。診断中は、ブラウザの「更新」「戻る」ボタンを押さないようにご注意ください。</p>
            </div>
        </div>
        <div class="go-home">
            <button @click="multipleHandler" class="start__cont--btn">診断トップに戻る</button>
        </div>
    </div>
</template>
<style lang="scss">
.q-logo {
    width: 150px;
}
.q-no {
    display: block;
    width: 130px;
    margin: 30px auto 20px auto;
}
.next-btn {
    margin: 0 auto;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 5px 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #e3ede0;
    box-shadow: 0px -3px 10px rgba(0,0,0,0.1);
    &__wrap {
        display: flex;
    }
    &__next {
        margin: 10px;
        flex: 3;
        padding: 25px 0;
        background-color: #679653;
        border-radius: 10px;
        border: none;
        font-family: 'yasashisa';
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
    &__back {
        margin: 10px 10px 10px 0;
        flex: 1;
        padding: 25px 0;
        background-color: #dcdcdc;
        border-radius: 10px;
        border: none;
        font-family: 'yasashisa';
        font-size: 18px;
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
}
.back-to-top {
    margin: 5px auto;
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
}
.error-handling {
    width: 90%;
    margin: 60px auto;
    border-radius: 10px;
    &__ttl {
        font-family: 'yasashisa';
    }
};

// transition css
.q-no-enter-from {
    opacity: 0;
    transform: translateY(30px);
};
.q-no-enter-active {
    transition: all 0.5s ease-in;
};
.q-no-enter-to {
    opacity: 1;
};

.q-cont-enter-from {
    opacity: 0;
    transform: translateY(30px);
};
.q-cont-enter-active {
    transition: all 0.6s ease-in;
};
.q-cont-enter-to {
    opacity: 1;
};

.q-choice-enter-from {
    opacity: 0;
    transform: translateY(30px);
};
.q-choice-enter-active {
    transition: all 0.7s ease-in;
};
.q-choice-enter-to {
    opacity: 1;
};
</style>
<script>
import QuestionContent from './QuestionContent.vue';
import QuestionChoices from './QuestionChoices.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        QuestionContent,
        QuestionChoices
    },
    data() {
        return {
            btnMessage: "つぎの質問へ",
            qIsVisible: false,
            contIsVisible: false,
            choiceIsVisible: false,
            qNoLoaded: false
        }
    },
    methods: {
        // when push next or finish question
        multipleHandler() {
            this.resetQuestionNo();
            this.redirectToTopDomain();
        },
        goNext() {
            this.nextAction();
            this.scrollTop();
        },
        redirectToTopDomain() {
            window.location.href = '/';
        },
        backQuestion() {
            this.backAction()
            this.scrollTop();
        },
        resetQuestionNo() {
            this.$store.commit('resetQuestionNo');
        },
        scrollTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
        },
        nextAction() {
            if (this.currentQuestionNo < 11) {
                this.increaseQuestionNo();
                this.qIsVisible = false;
                this.contIsVisible = false;
                this.choiceIsVisible = false;
            } else if (this.currentQuestionNo === 11) {
                this.increaseQuestionNo();
                this.changeMsg();
                this.qIsVisible = false;
                this.contIsVisible = false;
                this.choiceIsVisible = false;
            } else if (this.currentQuestionNo === 12) {
                this.goResult();
            }
        },
        changeMsg() {
            if (this.btnMessage === "つぎの質問へ") {
                this.btnMessage = "診断結果へ"
            } else {
                this.btnMessage = "つぎの質問へ"
            }
        },
        goResult() {
            this.$router.push('/results');
        },
        increaseQuestionNo() {
            this.$store.commit('increaseQuestionNo');
        },
        // when push back button
        backAction() {
            if (this.currentQuestionNo === 0) {
                this.goBack();
                this.qIsVisible = false;
                this.contIsVisible = false;
                this.choiceIsVisible = false;
            } else if (this.currentQuestionNo === 1) {
                this.decreaseQuestionNo();
                this.goBack();
                this.qIsVisible = false;
                this.contIsVisible = false;
                this.choiceIsVisible = false;
            } else if (this.currentQuestionNo <= 11) {
                this.decreaseQuestionNo();
                this.qIsVisible = false;
                this.contIsVisible = false;
                this.choiceIsVisible = false;
            } else if (this.currentQuestionNo === 12) {
                this.decreaseQuestionNo();
                this.changeMsg();
                this.qIsVisible = false;
                this.contIsVisible = false;
                this.choiceIsVisible = false;
            } else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push('/');
        },
        decreaseQuestionNo() {
            this.$store.commit('decreaseQuestionNo');
        }
    },
    computed: {
        ...mapGetters(['currentQuestionNo'])
    },
    watch: {
        currentQuestionNo: function(newVal) {
            console.log('currentQuestionIs: '+ newVal)
            if (newVal === 0) {
                window.location.href = '/';
            }
        }
    },
    mounted() {
        this.qIsVisible = true
        this.contIsVisible = true
        this.choiceIsVisible = true
    },
    updated() {
        this.qIsVisible = true
        this.contIsVisible = true
        this.choiceIsVisible = true
    }
}
</script>