<template>
    <transition name="result">
        <div class="container" v-if="resultIsVisible">
            <img src="@/assets/abc_logo_yoko.png" alt="asian beauty clinic" class="q-logo">
            <div class="start__main">
                <h1 class="start__main--title">診断結果</h1>
                <p class="start__main--header">上半身の重心側、下半身の重心側、五行タイプ<br>の3つを診断結果をご紹介します♪</p>
                <img :src="require(`@/assets/face_${getResultKey()}.png`)" alt="asian beauty clinic" class="result-face" @load="resultImg = true">
            </div>
            <!-- 上半身の診断結果 -->
            <div class="result-ttl">
                <h2 class="result-ttl--txt">診断①：上半身の重心側</h2>
            </div>
            <div class="result-gravity">
                <p class="result-gravity__ttl">{{ topGravityResult() }}</p>
                <p class="result-gravity__txt" v-if="topGravityResult() === 'ひだり'">
                    胃、膵臓、心臓に<br>
                    負担が掛かりやすい傾向があります。<br>
                    左の肩から頭痛・歯痛・<br>
                    腕・手先の痛み､痺れが出やすいのでは。
                </p>
                <p class="result-gravity__txt" v-if="topGravityResult() === 'みぎ'">
                    肝臓に負担が<br>
                    掛かりやすい傾向があります。<br>
                    ストレスを溜めやすい、<br>
                    右の肩、頭、腕の痛み、痺れが出やすいのでは。
                </p>
            </div>
            <!-- 下半身の診断結果 -->
            <div class="result-ttl">
                <h2 class="result-ttl--txt">診断②：下半身の重心側</h2>
            </div>
            <div class="result-gravity">
                <p class="result-gravity__ttl">{{ bottomGravityResult() }}</p>
                <p class="result-gravity__txt" v-if="bottomGravityResult() === 'ひだり'">
                    便秘がちな傾向があります。<br>
                    左の腰から足先までの痛み、<br>
                    痺れ、腫れが出やすいのでは。
                </p>
                <p class="result-gravity__txt" v-if="bottomGravityResult() === 'みぎ'">
                    下痢し易い傾向があります。<br>
                    右の腰から足先まで痛み、<br>
                    痺れ、腫れが出やすいのでは。
                </p>
            </div>
            <!-- 五行タイプ診断結果 -->
            <div class="result-ttl">
                <h2 class="result-ttl--txt">診断③：あなたの五行タイプ</h2>
            </div>
            <div class="result-gogyo">
                <img :src="require(`@/assets/kanji_${getResultKey()}.png`)" alt="asian beauty clinic" class="result-gogyo__img">
                
                <!-- 特徴 -->
                <div class="result-gogyo__char" v-if="getResultKey() === 'wed'">
                    <p class="result-gogyo__char--ttl">水タイプさんの特徴</p>
                    <ul class="result-gogyo__char--ul">
                        <li class="result-gogyo__char--li">気力が低下して疲れやすい</li>
                        <li class="result-gogyo__char--li">血液循環の不調から冷えやすく、むくみやすい</li>
                        <li class="result-gogyo__char--li">耳鳴りが起こりやすい</li>
                        <li class="result-gogyo__char--li">顔が黒っぽい</li>
                        <li class="result-gogyo__char--li">疲れやすい</li>
                        <li class="result-gogyo__char--li">不安になりやすい</li>
                    </ul>
                </div>
                <div class="result-gogyo__char" v-if="getResultKey() === 'tue'">
                    <p class="result-gogyo__char--ttl">火タイプさんの特徴</p>
                    <ul class="result-gogyo__char--ul">
                        <li class="result-gogyo__char--li">赤ら顔になりやすい</li>
                        <li class="result-gogyo__char--li">日常的に、汗をかきやすい</li>
                        <li class="result-gogyo__char--li">舌や口腔内が荒れやすい</li>
                        <li class="result-gogyo__char--li">動悸、息切れしやすい</li>
                        <li class="result-gogyo__char--li">汗をかきやすい</li>
                        <li class="result-gogyo__char--li">いつも微笑んでいる</li>
                    </ul>
                </div>
                <div class="result-gogyo__char" v-if="getResultKey() === 'fri'">
                    <p class="result-gogyo__char--ttl">金タイプさんの特徴</p>
                    <ul class="result-gogyo__char--ul">
                        <li class="result-gogyo__char--li">息切れしやすい</li>
                        <li class="result-gogyo__char--li">乾燥に弱い</li>
                        <li class="result-gogyo__char--li">鼻水、咳、たんが出やすい</li>
                        <li class="result-gogyo__char--li">声が枯れやすい</li>
                        <li class="result-gogyo__char--li">顔が白っぽい</li>
                        <li class="result-gogyo__char--li">風邪を引きやすい</li>
                    </ul>
                </div>
                <div class="result-gogyo__char" v-if="getResultKey() === 'thu'">
                    <p class="result-gogyo__char--ttl">木タイプさんの特徴</p>
                    <ul class="result-gogyo__char--ul">
                        <li class="result-gogyo__char--li">暴飲暴食しやすい</li>
                        <li class="result-gogyo__char--li">お腹から脇腹にかけて痛みやすい</li>
                        <li class="result-gogyo__char--li">疲れると目が赤くなったり涙が出やすい</li>
                        <li class="result-gogyo__char--li">筋肉が緊張しやすい(引きつりなど)</li>
                        <li class="result-gogyo__char--li">顔が青っぽい</li>
                        <li class="result-gogyo__char--li">怒ると目眩、耳鳴りがする</li>
                    </ul>
                </div>
                <div class="result-gogyo__char" v-if="getResultKey() === 'sat'">
                    <p class="result-gogyo__char--ttl">土タイプさんの特徴</p>
                    <ul class="result-gogyo__char--ul">
                        <li class="result-gogyo__char--li">胃腸で調子を整えていく</li>
                        <li class="result-gogyo__char--li">普段から、疲れを感じやすい</li>
                        <li class="result-gogyo__char--li">相手や周囲を想う気持ちが深い</li>
                        <li class="result-gogyo__char--li">顔が黄色っぽい</li>
                        <li class="result-gogyo__char--li">便秘、下痢を起こしやすい</li>
                        <li class="result-gogyo__char--li">楽しい時でも辛い時でもよく話す</li>
                    </ul>
                </div>

                <!-- アドバイス -->
                <div class="result-gogyo__adv" v-if="getResultKey() === 'wed'">
                    <p class="result-gogyo__adv--ttl">水タイプさんへのアドバイス</p>
                    <p class="result-gogyo__adv--expl">日常生活で疲れが溜まりやすいあなたは、腎や副腎を休ませたいところ。なるべくゆっくり休みを取れる日を作りましょう。無理しすぎず、半身浴などで足首を中心に身体を温めましょう。レッグウォーマーをするのもいいでしょう。アイスクリーム、ケーキ等の糖類はとくに身体を冷やすのも避けたいですね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'tue'">
                    <p class="result-gogyo__adv--ttl">火タイプさんへのアドバイス</p>
                    <p class="result-gogyo__adv--expl">冷えやすい身体の持ち主です。交感神経が興奮しやすいので、意識的に深呼吸してください。身体を温めてらミネラル食材を多く摂ってみてください。なるべくポジティブでプラス思考を心がけた毎日を送れるといいですね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'fri'">
                    <p class="result-gogyo__adv--ttl">金タイプさんへのアドバイス</p>
                    <p class="result-gogyo__adv--expl">なるべく十分な喉のケアとともに、皮膚の調子も整えてあげる必要があります。保湿を多めにしてみましょう。ゆっくり睡眠を心がけましょう。山芋、れんこん、モロヘイヤなどのネバネバ食材を意識的に取り入れてみてください。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'thu'">
                    <p class="result-gogyo__adv--ttl">木タイプさんへのアドバイス</p>
                    <p class="result-gogyo__adv--expl">日常に有酸素運動を取り入れ、気血を巡らせましょう。長く続けられるので、散歩がおすすめです。食事は柑橘類、特にレモンや、血を補ってくれる鉄分の 多い食材がおすすめ。暴飲暴食は避けてくださいね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'sat'">
                    <p class="result-gogyo__adv--ttl">土タイプさんへのアドバイス</p>
                    <p class="result-gogyo__adv--expl">自分なりのストレス解消法を見つけましょう。食事は量や時間をなるべく一定にして、消化の良いもので腹八分を心がけて。飲酒される方は、冷えを意識するとともになるべく量に注意しましょう。</p>
                </div>

                <!-- 食材 -->
                <div class="result-gogyo__adv" v-if="getResultKey() === 'wed'">
                    <p class="result-gogyo__adv--ttl">水タイプさんへのおすすめ食材</p>
                    <p class="result-gogyo__adv--expl">豚の肉、おから、栗、豆がおすすめ。ALE ONEの天日塩など、ミネラルをたっぷり取ってくださいね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'tue'">
                    <p class="result-gogyo__adv--ttl">火タイプさんへのおすすめ食材</p>
                    <p class="result-gogyo__adv--expl">羊の肉、らっきょう、杏、きびがおすすめ。ALE ONEの天日塩など、ミネラルをたっぷり取ってくださいね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'fri'">
                    <p class="result-gogyo__adv--ttl">金タイプさんへのおすすめ食材</p>
                    <p class="result-gogyo__adv--expl">馬の肉、ネギ、桃、お米がおすすめ。ALE ONEの天日塩など、ミネラルをたっぷり取ってくださいね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'thu'">
                    <p class="result-gogyo__adv--ttl">木タイプさんへのおすすめ食材</p>
                    <p class="result-gogyo__adv--expl">鶏の肉、麦、ニラ、スモモがおすすめ。ALE ONEの天日塩など、ミネラルをたっぷり取ってくださいね。</p>
                </div>
                <div class="result-gogyo__adv" v-if="getResultKey() === 'sat'">
                    <p class="result-gogyo__adv--ttl">土タイプさんへのおすすめ食材</p>
                    <p class="result-gogyo__adv--expl">牛の肉、わさび、なつめ、あわがおすすめ。ALE ONEの天日塩など、ミネラルをたっぷり取ってくださいね。</p>
                </div>
            </div>
            <div class="reco-food__intro">
                    <h1 class="start__main--title">おすすめ商品</h1>
                    <p class="start__main--header">診断結果はいかがでしたか？<br>ALE ONEでは、純粋天日塩を使った<br>身体に優しい商品を取り揃えています.</p>
            </div>
            <div class="reco-food">
                <a href="https://ale-one.com/products/the-salt-origin" target="_blank" class="reco-food__item">
                    <img src="@/assets/shio.jpg" alt="粒塩" class="reco-food__img">
                    <h3 class="reco-food__ttl">the salt<br>粒塩</h3>
                </a>
                <a  href="https://ale-one.com/products/the-shoyu-%E6%89%8B%E9%80%A0%E7%99%BA%E9%85%B5%E9%86%A4%E6%B2%B9" target="_blank" class="reco-food__item">
                    <img src="@/assets/shoyu.jpg" alt="手造発酵醤油" class="reco-food__img">
                    <h3 class="reco-food__ttl">the shoyu<br>手造発酵醤油</h3>
                </a>
                <a href="https://ale-one.com/products/the-miso" target="_blank" class="reco-food__item">
                    <img src="@/assets/miso.jpg" alt="手造発酵みそ" class="reco-food__img">
                    <h3 class="reco-food__ttl">the miso<br>手造発酵みそ</h3>
                </a>
            </div>
            <div class="reco-food">
                <a href="https://ale-one.com/products/20%E5%80%8B%E9%99%90%E5%AE%9A-the-bathsalt-be-positive-%E3%83%99%E3%83%AB%E3%82%AC%E3%83%A2%E3%83%83%E3%83%88-%E5%B0%8F-%E5%A4%A7%E3%81%A8%E3%82%82" target="_blank" class="reco-food__item">
                    <img src="@/assets/001_bel.webp" alt="ベルガモット" class="reco-food__img">
                    <h3 class="reco-food__ttl">the bathsalt<br>ベルガモット</h3>
                </a>
                <a  href="https://ale-one.com/products/20%E5%80%8B%E9%99%90%E5%AE%9A-the-bathsalt-good-sleep-%E3%83%A9%E3%83%99%E3%83%B3%E3%83%80%E3%83%BC-small" target="_blank" class="reco-food__item">
                    <img src="@/assets/002_rav.webp" alt="ラベンダー" class="reco-food__img">
                    <h3 class="reco-food__ttl">the bathsalt<br>ラベンダー</h3>
                </a>
                <a href="https://ale-one.com/products/20%E5%80%8B%E9%99%90%E5%AE%9A-the-bathsalt-stress-free-%E3%82%BC%E3%83%A9%E3%83%8B%E3%82%A6%E3%83%A0-small" target="_blank" class="reco-food__item">
                    <img src="@/assets/003_zer.webp" alt="ゼラニウム" class="reco-food__img">
                    <h3 class="reco-food__ttl">the bathsalt<br>ゼラニウム</h3>
                </a>
            </div>
            <div class="go-home">
                <button @click="multipleHandler" class="start__cont--btn">診断トップに戻る</button>
            </div>
            <div class="ao">
                <p class="ao__txt">Powered by</p>
                <a href="https://ale-one.com/">
                    <img src="@/assets/ALEONE_logo_long.png" alt="ale one" class="ao__logo">
                </a>
            </div>
        </div>
    </transition>
</template>
<style lang="scss">
.result-face {
    width: 40%;
}
.result-ttl {
    margin: 20px auto;
    padding: 10px;
    width: 90%;
    background-color: rgba(103, 150, 83, 0.1);
    border-radius: 10px;
    &--txt {
        font-size: 20px;
        font-family: 'yasashisa';
    }
}
.result-gravity {
    width: 90%;
    margin: 0 auto;
    &__ttl {
        font-size: 36px;
        margin-bottom: 20px;
    }
    &__txt {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 40px;
    }
}
.result-gogyo {
    width: 90%;
    margin: 0 auto;
    &__img {
        width: 40%;
    }
    &__char {
        width: 100%;
        margin: 0 auto 30px auto;
        padding: 5%;
        background-color: #FEF9E4;
        border-radius: 10px;
        line-height: 1.5;
        box-sizing: border-box;
        &--ttl {
            font-size: 16px;
            margin-bottom: 20px;
        }
        &--ul {
            font-family: 'yasashisa';
        }
        &--li {
            font-size: 16px;
            margin-bottom: 10px;
            text-align: left;
        }
    }
    &__adv {
        width: 100%;
        margin: 0 auto 30px auto;
        padding: 5%;
        background-color: #FEF9E4;
        border-radius: 10px;
        box-sizing: border-box;
        &--ttl {
            font-size: 16px;
            margin-bottom: 20px;
        }
        &--expl {
            font-size: 14px;
            line-height: 1.5;
            text-align: left;
        }
    }
}

.reco-food {
    display: flex;
    padding: 10px;
    &__intro {
        margin: 60px 0 15px 0;
    }
    &__item {
        flex: 1;
        background-color: white;
        border-radius: 5px;
        margin: 5px;
        text-decoration: none;
    };
    &__img {
        width: 100%;
        border-radius: 5px 5px 0px 0px;
    }
    &__ttl {
        font-size: 12px;
        text-align: center;
        text-decoration: none;
        color: black;
    };
    &__ttl:visited {
        text-decoration: none;
        color: black;
    }
}

.go-home {
    margin: 90px auto;
}

// transition css
.result-enter-from {
    opacity: 0;
    transform: translateY(30px);
};
.result-enter-active {
    transition: all 0.5s ease-in;
};
.result-enter-to {
    opacity: 1;
};
</style>
<script>
import {mapState} from 'vuex';
export default {
    data() {
        return {
            resultIsVisible: false,
            resultImg: false
        }
    },
    methods: {
        ...mapState(['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'tue', 'wed', 'thu', 'fri', 'sat']),
        multipleHandler() {
            this.resetQuestionNo();
            this.redirectToTopDomain();
        },
        resetQuestionNo() {
            this.$store.commit('resetQuestionNo');
        },
        redirectToTopDomain() {
            window.location.href = '/';
        },
        topGravityResult() {
            if (this.topGravityArray.filter(str => str === 'upLeft').length >= 2) {
                return `ひだり`
            } else {
                return `みぎ`
            }
        },
        bottomGravityResult() {
            if (this.bottomGravityArrayy.filter(str => str === 'downLeft').length >= 2) {
                return `ひだり`
            } else {
                return `みぎ`
            }
        },
        getResultKey() {
            const dataObject = {
                wed: this.wed.wed,
                tue: this.tue.tue,
                fri: this.fri.fri,
                thu: this.thu.thu,
                sat: this.sat.sat
            };

            let maxKey = 'wed';
            let maxValue = dataObject.wed;

            for (const key in dataObject) {
                if (dataObject[key] > maxValue) {
                    maxKey = key;
                    maxValue = dataObject[key];
                }
            }
            return maxKey;
        },
        gogyoResult() {
            if (this.getResultKey() === 'wed') {
                return `水タイプさん(腎)`
            } else if(this.getResultKey() === 'tue') {
                return `火タイプさん(心)`
            }else if(this.getResultKey() === 'fri') {
                return `金タイプさん(肺)`
            }else if(this.getResultKey() === 'thu') {
                return `木タイプさん(肝)`
            }else if(this.getResultKey() === 'sat') {
                return `土タイプさん(脾)`
            }
        }
    },
    computed: {
        ...mapState(['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'tue', 'wed', 'thu', 'fri', 'sat']),
        topGravityArray() {
            return [this.q4.q4,this.q5.q5,this.q6.q6]
        },
        bottomGravityArrayy() {
            return [this.q1.q1,this.q2.q2,this.q3.q3]
        }
    },
    mounted() {
        this.resultIsVisible = true
    }
}
</script>
