<template>
  <router-view></router-view>
</template>
<script>
export default {
  transition: 'fade'
}
</script>
<style lang="scss">
body {
  margin: 0 auto;
  max-width: 600px;
  background-color: #FCFCEF;
}
@font-face {
    font-family: 'yasashisa';
    src: url('~@/assets/fonts/07YasashisaAntique.otf') format('truetype');
}
#app {
  background-color: #FCFCEF;
}
p {
  font-family: 'yasashisa';
  margin: 0;
}
// transition css
.fade-enter-from {
    opacity: 0;
    transform: translateY(60px);
};
.fade-enter-active {
    transition: all 0.8s ease;
};
.fade-enter-leave {
    opacity: 1;
};
</style>
