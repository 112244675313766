<template>
    <form @submit.prevent="aaa">
        <div v-if="currentQuestionNo === 1">
            <div class="q-ans">
                <input type="radio" id="q1_001" name="q1" value="downLeft" class="hide" v-model="q1" @click="q1CommitLeft">
                <label for="q1_001" class="q-ans__radio" :class="q1 === 'downLeft' ? 'active' : ''">ひだり足</label>
                <input type="radio" id="q1_002" name="q1" value="downRight" class="hide" v-model="q1" @click="q1CommitRight">
                <label for="q1_002" class="q-ans__radio" :class="q1 === 'downRight' ? 'active' : ''">みぎ足</label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 2">
            <div class="q-ans">
                <input type="radio" id="q2_001" name="q2" value="downLeft" class="hide" v-model="q2" @click="q2CommitLeft">
                <label for="q2_001" class="q-ans__radio" :class="q2 === 'downLeft' ? 'active' : ''">ひだり足</label>
                <input type="radio" id="q2_002" name="q2" value="downRight" class="hide" v-model="q2" @click="q2CommitRight">
                <label for="q2_002" class="q-ans__radio" :class="q2 === 'downRight' ? 'active' : ''">みぎ足</label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 3">
            <div class="q-ans">                
                <input type="radio" id="q3_001" name="q3" value="downLeft" class="hide" v-model="q3" @click="q3CommitLeft">
                <label for="q3_001" class="q-ans__radio" :class="q3 === 'downLeft' ? 'active' : ''">下痢になりやすい</label>
                <input type="radio" id="q3_002" name="q3" value="downRight" class="hide" v-model="q3" @click="q3CommitRight">
                <label for="q3_002" class="q-ans__radio" :class="q3 === 'downRight' ? 'active' : ''">便秘になりやすい</label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 4">
            <div class="q-ans">
                <input type="radio" id="q4_001" name="q4" value="upLeft" class="hide" v-model="q4" @click="q4CommitLeft">
                <label for="q4_001" class="q-ans__radio" :class="q4 === 'upLeft' ? 'active' : ''">ひだりの脇腹</label>
                <input type="radio" id="q4_002" name="q4" value="upRight" class="hide" v-model="q4" @click="q4CommitRight">
                <label for="q4_002" class="q-ans__radio" :class="q4 === 'upRight' ? 'active' : ''">みぎの脇腹</label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 5">
            <div class="q-ans">
                <input type="radio" id="q5_001" name="q5" value="upLeft" class="hide" v-model="q5" @click="q5CommitLeft">
                <label for="q5_001" class="q-ans__radio" :class="q5 === 'upLeft' ? 'active' : ''">ひだりの肩</label>
                <input type="radio" id="q5_002" name="q5" value="upRight" class="hide" v-model="q5" @click="q5CommitRight">
                <label for="q5_002" class="q-ans__radio" :class="q5 === 'upRight' ? 'active' : ''">みぎの肩</label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 6">
            <div class="q-ans">
                <input type="radio" id="q6_001" name="q6" value="upLeft" class="hide" v-model="q6" @click="q6CommitLeft">
                <label for="q6_001" class="q-ans__radio" :class="q6 === 'upLeft' ? 'active' : ''">ひだりの頭</label>
                <input type="radio" id="q6_002" name="q6" value="upRight" class="hide" v-model="q6" @click="q6CommitRight">
                <label for="q6_002" class="q-ans__radio" :class="q6 === 'upRight' ? 'active' : ''">みぎの頭</label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 7">
            <div class="q-ans">
                <label for="q7_001" class="q-ans__check">
                    <input type="checkbox" id="q7_001" name="q7_001" class="q-ans__check--box" v-model="q7_001" @click="q7_001Commit">
                    足がむくみやすい
                </label>
                <label for="q7_002" class="q-ans__check">
                    <input type="checkbox" id="q7_002" name="q7_002" class="q-ans__check--box" v-model="q7_002" @click="q7_002Commit">
                    長時間立つと腰が痛む
                </label>
                <label for="q7_003" class="q-ans__check">
                    <input type="checkbox" id="q7_003" name="q7_003" class="q-ans__check--box" v-model="q7_003" @click="q7_003Commit">
                    いつも足腰が怠い
                </label>
                <label for="q7_004" class="q-ans__check">
                    <input type="checkbox" id="q7_004" name="q7_004" class="q-ans__check--box" v-model="q7_004" @click="q7_004Commit">
                    手足が冷えやすい
                </label>
                <label for="q7_005" class="q-ans__check">
                    <input type="checkbox" id="q7_005" name="q7_005" class="q-ans__check--box" v-model="q7_005" @click="q7_005Commit">
                    手足が痺れやすい
                </label>
                <label for="q7_006" class="q-ans__check">
                    <input type="checkbox" id="q7_006" name="q7_006" class="q-ans__check--box" v-model="q7_006" @click="q7_006Commit">
                    爪の形や色が悪く割れやすい
                </label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 8">
            <div class="q-ans">
                <label for="q8_001" class="q-ans__check">
                    <input type="checkbox" id="q8_001" name="q8_001" class="q-ans__check--box" v-model="q8_001" @click="q8_001Commit">
                    お腹全体が張ることがある
                </label>
                <label for="q8_002" class="q-ans__check">
                    <input type="checkbox" id="q8_002" name="q8_002" class="q-ans__check--box" v-model="q8_002" @click="q8_002Commit">
                    食後にお腹が張りやすい
                </label>
                <label for="q8_003" class="q-ans__check">
                    <input type="checkbox" id="q8_003" name="q8_003" class="q-ans__check--box" v-model="q8_003" @click="q8_003Commit">
                    腹痛を起こしやすい
                </label>
                <label for="q8_004" class="q-ans__check">
                    <input type="checkbox" id="q8_004" name="q8_004" class="q-ans__check--box" v-model="q8_004" @click="q8_004Commit">
                    下痢しやすい
                </label>
                <label for="q8_005" class="q-ans__check">
                    <input type="checkbox" id="q8_005" name="q8_005" class="q-ans__check--box" v-model="q8_005" @click="q8_005Commit">
                    尿の切れが悪い
                </label>
                <label for="q8_006" class="q-ans__check">
                    <input type="checkbox" id="q8_006" name="q8_006" class="q-ans__check--box" v-model="q8_006" @click="q8_006Commit">
                    消化不良を起こしやすい
                </label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 9">
            <div class="q-ans">
                <label for="q9_001" class="q-ans__check">
                    <input type="checkbox" id="q9_001" name="q9_001" class="q-ans__check--box" v-model="q9_001" @click="q9_001Commit">
                    息切れしやすい
                </label>
                <label for="q9_002" class="q-ans__check">
                    <input type="checkbox" id="q9_002" name="q9_002" class="q-ans__check--box" v-model="q9_002" @click="q9_002Commit">
                    咳や痰が出やすい
                </label>
                <label for="q9_003" class="q-ans__check">
                    <input type="checkbox" id="q9_003" name="q9_003" class="q-ans__check--box" v-model="q9_003" @click="q9_003Commit">
                    声が枯れやすい
                </label>
                <label for="q9_004" class="q-ans__check">
                    <input type="checkbox" id="q9_004" name="q9_004" class="q-ans__check--box" v-model="q9_004" @click="q9_004Commit">
                    動悸や息切れがある
                </label>
                <label for="q9_005" class="q-ans__check">
                    <input type="checkbox" id="q9_005" name="q9_005" class="q-ans__check--box" v-model="q9_005" @click="q9_005Commit">
                    呼吸が浅くなることが多い
                </label>
                <label for="q9_006" class="q-ans__check">
                    <input type="checkbox" id="q9_006" name="q9_006" class="q-ans__check--box" v-model="q9_006" @click="q9_006Commit">
                    げっぷが出やすい
                </label>
                <label for="q9_007" class="q-ans__check">
                    <input type="checkbox" id="q9_007" name="q9_007" class="q-ans__check--box" v-model="q9_007" @click="q9_007Commit">
                    よく胸焼けがある
                </label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 10">
            <div class="q-ans">
                <label for="q10_001" class="q-ans__check">
                    <input type="checkbox" id="q10_001" name="q10_001" class="q-ans__check--box" v-model="q10_001" @click="q10_001Commit">
                    舌が荒れやすい
                </label>
                <label for="q10_002" class="q-ans__check">
                    <input type="checkbox" id="q10_002" name="q10_002" class="q-ans__check--box" v-model="q10_002" @click="q10_002Commit">
                    顔が赤くなりやすい
                </label>
                <label for="q10_003" class="q-ans__check">
                    <input type="checkbox" id="q10_003" name="q10_003" class="q-ans__check--box" v-model="q10_003" @click="q10_003Commit">
                    目が疲れやすい
                </label>
                <label for="q10_004" class="q-ans__check">
                    <input type="checkbox" id="q10_004" name="q10_004" class="q-ans__check--box" v-model="q10_004" @click="q10_004Commit">
                    目が乾きやすい
                </label>
                <label for="q10_005" class="q-ans__check">
                    <input type="checkbox" id="q10_005" name="q10_005" class="q-ans__check--box" v-model="q10_005" @click="q10_005Commit">
                    高音の耳鳴りがしやすい
                </label>
                <label for="q10_006" class="q-ans__check">
                    <input type="checkbox" id="q10_006" name="q10_006" class="q-ans__check--box" v-model="q10_006" @click="q10_006Commit">
                    低音の耳鳴りがしやすい
                </label>
                <label for="q10_007" class="q-ans__check">
                    <input type="checkbox" id="q10_007" name="q10_007" class="q-ans__check--box" v-model="q10_007" @click="q10_007Commit">
                    音が聞き取りにくい
                </label>
                <label for="q10_008" class="q-ans__check">
                    <input type="checkbox" id="q10_008" name="q10_008" class="q-ans__check--box" v-model="q10_008" @click="q10_008Commit">
                    老けて見えると言われることがある
                </label>
                <label for="q10_009" class="q-ans__check">
                    <input type="checkbox" id="q10_009" name="q10_009" class="q-ans__check--box" v-model="q10_009" @click="q10_009Commit">
                    鼻炎になりやすい
                </label>
                <label for="q10_010" class="q-ans__check">
                    <input type="checkbox" id="q10_010" name="q10_010" class="q-ans__check--box" v-model="q10_010" @click="q10_010Commit">
                    肌が乾燥しやすい
                </label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 11">
            <div class="q-ans">
                <label for="q11_001" class="q-ans__check">
                    <input type="checkbox" id="q11_001" name="q11_001" class="q-ans__check--box" v-model="q11_001" @click="q11_011Commit">
                    目眩がしやすい
                </label>
                <label for="q11_002" class="q-ans__check">
                    <input type="checkbox" id="q11_002" name="q11_002" class="q-ans__check--box" v-model="q11_002" @click="q11_002Commit">
                    風邪をひきやすい
                </label>
                <label for="q11_003" class="q-ans__check">
                    <input type="checkbox" id="q11_003" name="q11_003" class="q-ans__check--box" v-model="q11_003" @click="q11_003Commit">
                    寒さや暑さに敏感
                </label>
                <label for="q11_004" class="q-ans__check">
                    <input type="checkbox" id="q11_004" name="q11_004" class="q-ans__check--box" v-model="q11_004" @click="q11_004Commit">
                    寒気がすることが多い
                </label>
                <label for="q11_005" class="q-ans__check">
                    <input type="checkbox" id="q11_005" name="q11_005" class="q-ans__check--box" v-model="q11_005" @click="q11_005Commit">
                    倦怠感がある
                </label>
                <label for="q11_006" class="q-ans__check">
                    <input type="checkbox" id="q11_006" name="q11_006" class="q-ans__check--box" v-model="q11_006" @click="q11_006Commit">
                    冷や汗をかきやすい
                </label>
                <label for="q11_007" class="q-ans__check">
                    <input type="checkbox" id="q11_007" name="q11_007" class="q-ans__check--box" v-model="q11_007" @click="q11_007Commit">
                    寝付きが悪いことがある
                </label>
                <label for="q11_008" class="q-ans__check">
                    <input type="checkbox" id="q11_008" name="q11_008" class="q-ans__check--box" v-model="q11_008" @click="q11_008Commit">
                    食欲不振になることが多い
                </label>
            </div>
        </div>
        <div v-if="currentQuestionNo === 12">
            <div class="q-ans">
                <label for="q12_001" class="q-ans__check">
                    <input type="checkbox" id="q12_001" name="q12_001" class="q-ans__check--box" v-model="q12_001" @click="q12_001Commit">
                    すぐに落ち込んでしまう
                </label>
                <label for="q12_002" class="q-ans__check">
                    <input type="checkbox" id="q12_002" name="q12_002" class="q-ans__check--box" v-model="q12_002" @click="q12_002Commit">
                    物忘れが多い
                </label>
                <label for="q12_003" class="q-ans__check">
                    <input type="checkbox" id="q12_003" name="q12_003" class="q-ans__check--box" v-model="q12_003" @click="q12_003Commit">
                    動揺しやすい
                </label>
                <label for="q12_004" class="q-ans__check">
                    <input type="checkbox" id="q12_004" name="q12_004" class="q-ans__check--box" v-model="q12_004" @click="q12_004Commit">
                    ストレスで血圧が上がりやすい
                </label>
                <label for="q12_005" class="q-ans__check">
                    <input type="checkbox" id="q12_005" name="q12_005" class="q-ans__check--box" v-model="q12_005" @click="q12_005Commit">
                    怒りっぽい
                </label>
                <label for="q12_006" class="q-ans__check">
                    <input type="checkbox" id="q12_006" name="q12_006" class="q-ans__check--box" v-model="q12_006" @click="q12_006Commit">
                    心配事が絶えない
                </label>
                <label for="q12_007" class="q-ans__check">
                    <input type="checkbox" id="q12_007" name="q12_007" class="q-ans__check--box" v-model="q12_007" @click="q12_007Commit">
                    疲れやすい
                </label>
                <label for="q12_008" class="q-ans__check">
                    <input type="checkbox" id="q12_008" name="q12_008" class="q-ans__check--box" v-model="q12_008" @click="q12_008Commit">
                    何に対しても怖がりやすい
                </label>
            </div>
        </div>
    </form>
</template>
<style lang="scss">
.hide {
    display: none;
}
.active {
    border: solid 5px #679653 !important;
}
.q-ans {
    margin: 40px auto 90px auto;
    &__radio {
        margin: 0 auto 20px auto;
        padding: 25px 0;
        display: block;
        width: 90%;
        font-size: 24px;
        font-family: 'yasashisa';
        background-color: rgba(103, 150, 83, 0.1);
        border-radius: 10px;
        box-sizing: border-box;
        border: solid 5px rgba(103, 150, 83, 0.1);
        cursor: pointer;
    }
    &__check {
        text-align: left;
        display: block;
        width: 90%;
        margin: 0 auto 15px auto;
        padding: 20px 10px;
        background-color: rgba(103, 150, 83, 0.1);
        border-radius: 10px;
        font-family: 'yasashisa';
        font-size: 16px;
        cursor: pointer;
        &--box {
            accent-color: #679653;
            inline-size: 20px;
            block-size: 20px;
            vertical-align: sub;
        }
    }
}
</style>
<script>
    import { mapGetters } from 'vuex';
    export default {
        data() {
            return {
                q1: this.$store.getters.q1,
                q2: this.$store.getters.q2,
                q3: this.$store.getters.q3,
                q4: this.$store.getters.q4,
                q5: this.$store.getters.q5,
                q6: this.$store.getters.q6,
                q7_001: false,
                q7_002: false,
                q7_003: false,
                q7_004: false,
                q7_005: false,
                q7_006: false,
                q8_001: false,
                q8_002: false,
                q8_003: false,
                q8_004: false,
                q8_005: false,
                q8_006: false,
                q9_001: false,
                q9_002: false,
                q9_003: false,
                q9_004: false,
                q9_005: false,
                q9_006: false,
                q9_007: false,
                q10_001: false,
                q10_002: false,
                q10_003: false,
                q10_004: false,
                q10_005: false,
                q10_006: false,
                q10_007: false,
                q10_008: false,
                q10_009: false,
                q10_010: false,
                q11_001: false,
                q11_002: false,
                q11_003: false,
                q11_004: false,
                q11_005: false,
                q11_006: false,
                q11_007: false,
                q11_008: false,
                q12_001: false,
                q12_002: false,
                q12_003: false,
                q12_004: false,
                q12_005: false,
                q12_006: false,
                q12_007: false,
                q12_008: false
            }
        },
        methods: {
            q1CommitLeft(){
                this.$store.commit('changeQ1',"downLeft")
            },
            q1CommitRight(){
                this.$store.commit('changeQ1',"downRight")
            },
            q2CommitLeft(){
                this.$store.commit('changeQ2',"downLeft")
            },
            q2CommitRight(){
                this.$store.commit('changeQ2',"downRight")
            },
            q3CommitLeft(){
                this.$store.commit('changeQ3',"downLeft")
            },
            q3CommitRight(){
                this.$store.commit('changeQ3',"downRight")
            },
            q4CommitLeft(){
                this.$store.commit('changeQ4',"upLeft")
            },
            q4CommitRight(){
                this.$store.commit('changeQ4',"upRight")
            },
            q5CommitLeft(){
                this.$store.commit('changeQ5',"upLeft")
            },
            q5CommitRight(){
                this.$store.commit('changeQ5',"upRight")
            },
            q6CommitLeft(){
                this.$store.commit('changeQ6',"upLeft")
            },
            q6CommitRight(){
                this.$store.commit('changeQ6',"upRight")
            },
            q7_001Commit() {
                if(this.q7_001 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q7_001 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q7_002Commit() {
                if(this.q7_002 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q7_002 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q7_003Commit() {
                if(this.q7_003 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q7_003 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q7_004Commit() {
                if(this.q7_004 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q7_004 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q7_005Commit() {
                if(this.q7_005 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q7_005 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q7_006Commit() {
                if(this.q7_006 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q7_006 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q8_001Commit() {
                if(this.q8_001 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q8_001 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q8_002Commit() {
                if(this.q8_002 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q8_002 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q8_003Commit() {
                if(this.q8_003 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q8_003 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q8_004Commit() {
                if(this.q8_004 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q8_004 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q8_005Commit() {
                if(this.q8_005 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q8_005 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q8_006Commit() {
                if(this.q8_006 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q8_006 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q9_001Commit() {
                if(this.q9_001 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q9_001 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q9_002Commit() {
                if(this.q9_002 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q9_002 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q9_003Commit() {
                if(this.q9_003 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q9_003 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q9_004Commit() {
                if(this.q9_004 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q9_004 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q9_005Commit() {
                if(this.q9_005 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q9_005 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q9_006Commit() {
                if(this.q9_006 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q9_006 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q9_007Commit() {
                if(this.q9_007 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q9_007 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q10_001Commit() {
                if(this.q10_001 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q10_001 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q10_002Commit() {
                if(this.q10_002 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q10_002 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q10_003Commit() {
                if(this.q10_003 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q10_003 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q10_004Commit() {
                if(this.q10_004 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q10_004 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q10_005Commit() {
                if(this.q10_005 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q10_005 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q10_006Commit() {
                if(this.q10_006 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q10_006 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q10_007Commit() {
                if(this.q10_007 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q10_007 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q10_008Commit() {
                if(this.q10_008 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q10_008 === true) {
                    this.$store.commit('decreaseWED')
                }
            },
            q10_009Commit() {
                if(this.q10_009 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q10_009 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q10_010Commit() {
                if(this.q10_010 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q10_010 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q11_011Commit() {
                if(this.q11_001 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q11_001 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q11_002Commit() {
                if(this.q11_002 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q11_002 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q11_003Commit() {
                if(this.q11_003 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q11_003 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q11_004Commit() {
                if(this.q11_004 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q11_004 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q11_005Commit() {
                if(this.q11_005 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q11_005 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q11_006Commit() {
                if(this.q11_006 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q11_006 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q11_007Commit() {
                if(this.q11_007 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q11_007 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q11_008Commit() {
                if(this.q11_008 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q11_008 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q12_001Commit() {
                if(this.q12_001 === false) {
                    this.$store.commit('increaseFRI')
                } else if(this.q12_001 === true) {
                    this.$store.commit('decreaseFRI')
                }
            },
            q12_002Commit() {
                if(this.q12_002 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q12_002 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q12_003Commit() {
                if(this.q12_003 === false) {
                    this.$store.commit('increaseTUE')
                } else if(this.q12_003 === true) {
                    this.$store.commit('decreaseTUE')
                }
            },
            q12_004Commit() {
                if(this.q12_004 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q12_004 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q12_005Commit() {
                if(this.q12_005 === false) {
                    this.$store.commit('increaseTHU')
                } else if(this.q12_005 === true) {
                    this.$store.commit('decreaseTHU')
                }
            },
            q12_006Commit() {
                if(this.q12_006 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q12_006 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q12_007Commit() {
                if(this.q12_007 === false) {
                    this.$store.commit('increaseSAT')
                } else if(this.q12_007 === true) {
                    this.$store.commit('decreaseSAT')
                }
            },
            q12_008Commit() {
                if(this.q12_008 === false) {
                    this.$store.commit('increaseWED')
                } else if(this.q12_008 === true) {
                    this.$store.commit('decreaseWED')
                }
            }
        },
        computed: {
            ...mapGetters(['currentQuestionNo'])
        }
    }
</script>